<template>
  <collect-container>
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item prop="platform" label="您想在哪个平台开店：">
        <el-select v-model="form.platform">
          <el-option
            v-for="item in platforms"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="contact" label="您的称呼">
        <el-input v-model="form.contact" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item prop="mobile" label="联系电话">
        <el-input v-model="form.mobile" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item
        label="您是从什么渠道了解魔筷的?"
        prop="source"
        class="learn_item"
      >
        <el-select
          v-model="form.source"
          placeholder="请选择"
          @change="where_learn"
        >
          <el-option
            v-for="(item, index) in optionData"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="learnInfo" v-if="visible">
        <el-input
          v-model="form.learnInfo"
          placeholder="请输入"
          class="learn"
        ></el-input>
      </el-form-item>
      <div class="submit-btn" @click="submit">立即提交</div>
    </el-form>
    <collect-success-modal
      :visible="modalVisible"
      @close="closeModal"
    ></collect-success-modal>
  </collect-container>
</template>

<script>
import util from '@/js/util'
import CollectContainer from '@/components/CollectContainer.vue'
import CollectSuccessModal from '@/components/CollectSuccessModal.vue'

const mobileCheck = (rule, value, callback) => {
  if (!util.isPhoneAvailable(value)) {
    return callback(new Error('请输入正确的手机号'))
  }
  callback()
}

export default {
  components: {
    CollectContainer,
    CollectSuccessModal,
  },
  data() {
    return {
      modalVisible: false,
      platforms: [
        '快手',
        '微信视频号',
        '全民k歌',
        '微视',
        '腾讯视频',
        '企鹅号',
        'QQ',
      ],
      form: {
        contact: '',
        mobile: '',
        platform: '',
        source: '',
        learnInfo: '',
      },
      formOption: '',
      optionData: [
        '朋友介绍',
        '百度搜索',
        '媒体报道',
        '大型活动(行业展会、会议等)',
        '其他',
      ],
      visible: false,
      rules: {
        platform: {
          required: true,
          message: '请选择平台',
          trigger: 'change',
        },
        contact: {
          required: true,
          message: '请输入姓名',
          trigger: 'change',
        },
        mobile: [
          {
            required: true,
            message: '请输入联系电话',
            trigger: 'change',
          },
          { validator: mobileCheck, trigger: 'blur' },
        ],
        source: [{ required: true, message: '请输入渠道', trigger: 'change' }],
        learnInfo: [
          {
            required: false,
            message: '请输入渠道',
            trigger: 'change',
          },
        ],
      },
    }
  },
  methods: {
    closeModal() {
      this.modalVisible = false
    },
    submit() {
      this.$refs.form.validate((res) => {
        console.log(res)
        if (res) {
          // console.log('提交的数据', this.form)
          if (this.visible) {
            this.form.source = this.form.learnInfo
          }
          delete this.form.learnInfo
          this.dispatchRequest()
        }
      })
    },
    dispatchRequest() {
      window.lib.api.post({
        api: 'ec/a/boss/shop/add',
        data: {
          ...this.form,
        },
        success: (res) => {
          console.log(res)
          this.$refs.form.resetFields()
          this.modalVisible = true
          this.visible = false
        },
        error: (err) => {
          this.$message.error(err.msg)
        },
      })
    },
    where_learn(value) {
      this.visible = value == '其他' ? true : false
      this.rules.learnInfo[0].required = this.visible ? true : false
    },
  },
}
</script>

<style lang="less" scoped>
@import '../style/common.less';

.submit-btn {
  box-sizing: border-box;
  width: 360px;
  height: 60px;
  line-height: 60px;
  margin: 57px auto 0;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ff6600;
  background-color: #fff;
  color: #ff6600;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: #ff6600;
    color: #fff;
  }
}
.el-form {
  text-align: center;
  .el-form-item {
    width: 520px;
    /deep/ .el-form-item__label {
      float: left;
      padding-left: 19px;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      &::before {
        content: '';
        margin-right: 0;
      }
    }
  }
}

.el-select {
  width: 100%;
}

.learn_item {
  .pxToVw(margin-bottom, 20);
}
/deep/ .el-input__inner {
  height: 52px;
  border-radius: 4px;
  &::placeholder {
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #999999;
  }
  &:focus {
    border: 1px solid #ff6600;
  }
}
</style>
